import { getQAId } from "./components/design/constants"

export const NEW_MODULE_TYPES = {
  NOTHING: "NOTHING",

  BACKGROUND: "BACKGROUND",
  PROGRESS_BAR: "PROGRESS_BAR",

  //Survey Request Section
  SURVEYREQUEST: "SURVEYREQUEST",
  SURVEYREQUEST_BACKGROUND: "SURVEYREQUEST_BACKGROUND",
  SURVEYREQUEST_CLOSEBUTTON: "SURVEYREQUEST_CLOSEBUTTON",
  SURVEYREQUEST_IMAGE: "SURVEYREQUEST_IMAGE",
  SURVEYREQUEST_TITLE: "SURVEYREQUEST_TITLE",
  SURVEYREQUEST_DESC: "SURVEYREQUEST_DESC",
  SURVEYREQUEST_BUTTONCONTAINER: "SURVEYREQUEST_BUTTONCONTAINER",
  SURVEYREQUEST_BUTTONS: "SURVEYREQUEST_BUTTONS",
  SURVEYREQUEST_BUTTON0: "SURVEYREQUEST_BUTTON0",
  SURVEYREQUEST_BUTTON1: "SURVEYREQUEST_BUTTON1",
  SURVEYREQUEST_BUTTON0_TEXT: "SURVEYREQUEST_BUTTON0_TEXT",
  SURVEYREQUEST_BUTTON1_TEXT: "SURVEYREQUEST_BUTTON1_TEXT",

  //Success Message Section
  SUCCESSMESSAGE: "SUCCESSMESSAGE",
  SUCCESSMESSAGE_BACKGROUND: "SUCCESSMESSAGE_BACKGROUND",
  SUCCESSMESSAGE_IMAGE: "SUCCESSMESSAGE_IMAGE",
  SUCCESSMESSAGE_TITLE: "SUCCESSMESSAGE_TITILE",
  SUCCESSMESSAGE_DESC: "SUCCESSMESSAGE_DESC",

  // Header Section
  HEADER: "HEADER",
  PAGINATION: "PAGINATION",
  HEADER_TITLE: "HEADER_TITLE",
  HEADER_DESC: "HEADER_DESC",
  BACK_BUTTON: "BACK_BUTTON",
  CLOSE_BUTTON: "CLOSE_BUTTON",
  IMAGE: "IMAGE",
  STEPPER: "STEPPER",

  // Body Section
  BODY: "BODY",
  SURVEY_CLOSE_BUTTON: "SURVEY_CLOSE_BUTTON",
  QUESTION_TEXT: "QUESTION_TEXT",
  QUESTION_DESC: "QUESTION_DESC",
  QUESTION_CHOICE_PROMPT: "QUESTION_CHOICE_PROMPT",
  ANSWER: "ANSWER",
  OPTION: "OPTION",
  OPTION_TEXT: "OPTION_TEXT",
  RADIO: "RADIO",
  ICON: "ICON",
  INPUT_TEXT: "INPUT_TEXT",
  PLACEHOLDER: "PLACEHOLDER",

  // Footer Section
  FOOTER: "FOOTER",
  BUTTON: "BUTTON",
  BUTTON_TEXT: "BUTTON_TEXT",

  // NPS
  RATING_BUTTON: "RATING_BUTTON",
  RATING_BUTTON_TEXT: "RATING_BUTTON_TEXT",
  RATING_LABEL_START: "RATING_LABEL_START",
  RATING_LABEL_END: "RATING_LABEL_END",
  RATING_Q_AND_S_QUESTION: "RATING_Q_AND_S_QUESTION",
  RATING_Q_AND_S_SUGGESTION: "RATING_Q_AND_S_SUGGESTION",
  RATING_Q_AND_S_SUGGESTION_BG: "RATING_Q_AND_S_SUGGESTION_BG",
  RATING_REACTION_TEXT: "RATING_REACTION_TEXT",
  RATING_REACTION_IMAGE: "RATING_REACTION_IMAGE",
  RATING_REACTION_EMOJI: "RATING_REACTION_EMOJI",
  RATING_REACTION_EMOJI_BACKGROUND: "RATING_REACTION_EMOJI_BACKGROUND",

  // NPS Slider
  SLIDER: "SLIDER",
}

export const MAX_CHARACTERS_LIMIT = 150

export const PADDING = {
  enable_padding: true,
  padding: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
}

export const MARGIN = {
  enable_margin: true,
  margin: {
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
  },
}

export const BORDER = {
  enable_border: true,
  border: {
    radius: 0,
    inactive_color: "#000000", // used in "numbered", "radio", and "star" rating types
    color: "#000000",
    width: 0,

    /**
     * "solid" | "dashed" | "dotted"
     */
    style: "solid",
  },
}

export const FONT = {
  font: {
    family: "sans-serif",
    size: 18,

    /**
     * "normal" || "italic"
     */
    style: "normal",

    /**
     * "normal" || "bold"
     */
    weight: "normal",
  },
}

export const GRADIENT = {
  enable_gradient: false,
  gradient: {
    /**
     * "to right" || "to left" || "to top" || "to bottom"
     */
    direction: "to right",

    colors: ["#FBBD37", "#F98B45"],
  },
}

export const GENERIC_TEXT = {
  text: "Text",
  color: "#000000",
  background_color: "transparent",
  max_characters: MAX_CHARACTERS_LIMIT,

  /**
   * "left" || "center" || "right" || "justify"
   */
  alignment: "left",
  ...FONT,
  ...PADDING,
  ...MARGIN,
}

export const TITLE = {
  enable_title: true,
  title: {
    ...GENERIC_TEXT,
  },
}

export const DESCRIPTION = {
  enable_description: true,
  description: {
    ...GENERIC_TEXT,
  },
}

export const CHOICE_PROMPT = {
  enable_choice_prompt: false,
  choice_prompt: {
    ...GENERIC_TEXT,
    font: {
      ...GENERIC_TEXT.font,
      size: 12,
    },
    margin: {
      ...GENERIC_TEXT.margin,
      top: 0,
      bottom: 12,
      right: 16,
      left: 16,
    },
  },
}

export const DIM_BACKGROUND = {
  enable_dimbackground: false,
  dimbackground: {
    color: "#000000",
    opacity: 0.8,
  },
}

export const POSITION = {
  position: {
    position: "",
  },
}

export const PROGRESS_BAR = {
  enable_progressbar: false,
  progressbar: {
    /**
     * "top" || "bottom"
     */
    position: "top",

    width: 5,
    progress_color: "#FF0500",
    remaining_color: "#A1C2F1",
  },
}

export const STEPPER = {
  enable_stepper: true,
  stepper: {
    /**
     * "question_of" || "question_slash" || "of" || "slash"
     */
    type: "question_of",

    enable_numbered: false,
    pagination_text: {
      ...GENERIC_TEXT,
      text: "Question",
    },
  },
}

export const BACKGROUND_IMAGE = {
  enable_background_image: false,
  background_image: {
    mask_opacity: 0.5,
    mask_color: "#FFF",
  },
}

export const IMAGE = {
  enable_image: false,
  image: {
    height: 360,
    width: 360,
    path: "",
    ...BORDER,
    ...BACKGROUND_IMAGE,
  },
}

export const SCROLL = {
  enable_scroll: false,
  scroll: {
    height: 300,
    ...BORDER,
  },
}

export const SHADOW = {
  enable_shadow: false, // 1.04 scale on active, 0.96 on inactive //transform scale
  shadow: {
    color: "#000000",
    offset_x: 0,
    offset_y: 0,
    blur_radius: 6,
    spread_radius: 6,
  },
}

export const PLACEHOLDER = {
  enable_placeholder: true,
  placeholder: {
    text: "Give your reason...",
    color: "#999999",
  },
}

export const INPUT_TEXT = {
  input_text: {
    ...PLACEHOLDER,
    ...BORDER,
    border: {
      ...BORDER.border,
      radius: 10,
      width: 1,
    },
    background_color: "#FFFFFF",
    max_characters: MAX_CHARACTERS_LIMIT,
    text_color: "#000000",
  },
}

export const ICON = {
  enable_icon: false,
  icon: {
    path: "",

    /**
     * "url" || "path"
     */
    type: "url",
    height: 60,
    width: 60,

    /**
     * "start" || "center" || "end"
     */
    position: "center",
    ...BORDER,
  },
}

export const BUTTON_ACTION = {
  enable_action: true,
  action: {
    /**
     * "next" || "dismiss" || "close" || "submit" || "skip" || "yes" || "no" || "previous" || "redirect"
     */
    type: "next",

    url: "",
    is_external: false,
    is_ir: false,
    is_within: false,
  },
}

export const BUTTON = {
  /**
   * "normal" || "wide"
   */
  type: "normal",

  active_color: "#5965EC",
  inactive_color: "#A1C2F1",
  ...BUTTON_ACTION,
  ...BORDER,
  border: {
    ...BORDER.border,
    radius: 10,
  },
  text: {
    ...GENERIC_TEXT,
    text: "Next",
    color: "#FFFFFF",
    alignment: "center",
    font: {
      ...GENERIC_TEXT.font,
      size: 16,
    },
  },
  position: {
    ...POSITION.position,

    /**
     * @type "L" || "C" || "R"
     *
     * @description
     * "L" - Left
     * "C" - Center
     * "R" - Right
     */
    position: "R",
  },
  ...MARGIN,
  margin: {
    ...MARGIN.margin,
    top: 35,
    bottom: 32,
    left: 16,
    right: 16,
  },
  ...PADDING,
  padding: { ...PADDING.padding, top: 12, bottom: 12, right: 36, left: 36 },
}

export const SURVEYREQUESTBUTTON = {
  buttons: [
    {
      /**
       * "normal" || "wide"
       */
      type: "normal",

      active_color: "transparent",
      inactive_color: "#A1C2F1",
      ...BUTTON_ACTION,
      action: {
        ...BUTTON_ACTION.action,
        type: "no",
      },
      ...BORDER,
      border: {
        ...BORDER.border,
        radius: 10,
      },
      text: {
        ...GENERIC_TEXT,
        text: "Ask Later",
        color: "#6877F2",
        alignment: "center",
        font: {
          ...GENERIC_TEXT.font,
          size: 16,
        },
        margin: {
          ...MARGIN.margin,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        padding: {
          ...PADDING.padding,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      position: {
        ...POSITION.position,

        /**
         * @type "L" || "C" || "R"
         *
         * @description
         * "L" - Left
         * "C" - Center
         * "R" - Right
         */
        position: "L",
      },
      ...MARGIN,
      margin: {
        ...MARGIN.margin,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      ...PADDING,
      padding: { ...PADDING.padding, top: 12, bottom: 12, right: 24, left: 24 },
    },
    {
      /**
       * "normal" || "wide"
       */
      type: "normal",

      active_color: "#5965EC",
      inactive_color: "#A1C2F1",
      ...BUTTON_ACTION,
      action: {
        ...BUTTON_ACTION.action,
        type: "yes",
      },
      ...BORDER,
      border: {
        ...BORDER.border,
        radius: 10,
      },
      text: {
        ...GENERIC_TEXT,
        text: "Yes",
        color: "#FFFFFF",
        alignment: "center",
        font: {
          ...GENERIC_TEXT.font,
          size: 16,
        },
        margin: {
          ...MARGIN.margin,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        padding: {
          ...PADDING.padding,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      position: {
        ...POSITION.position,

        /**
         * @type "L" || "C" || "R"
         *
         * @description
         * "L" - Left
         * "C" - Center
         * "R" - Right
         */
        position: "R",
      },
      ...MARGIN,
      margin: {
        ...MARGIN.margin,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      ...PADDING,
      padding: { ...PADDING.padding, top: 12, bottom: 12, right: 24, left: 24 },
    },
  ],
}

export const BACK_BUTTON = {
  enable_back_button: true,
  back_button: {
    path: "",

    /**
     * "normal" || "filled"
     */
    type: "normal",
    color: "#FFFFFF",
    fill_color: "#000000",

    /**
     * "circle" || "rectangle"
     */
    shape: "circle",
    height: 20,
    width: 20,

    position: {
      /**
       * @type "TL" || "TR"
       *
       * @description
       * "TL" - Top Left
       * "TR" - Top Right
       */
      position: "TL",
    },

    ...MARGIN,
    margin: {
      ...MARGIN.margin,
      top: 12,
      bottom: 0,
      right: 0,
      left: 16,
    },
    ...BUTTON_ACTION,

    action: {
      ...BUTTON_ACTION.action,
      type: "previous",
    },
  },
}

export const CLOSE_BUTTON = {
  enable_close_button: true,
  close_button: {
    path: "",

    /**
     * "normal" || "filled"
     */
    type: "normal",
    color: "#FFFFFF",
    fill_color: "#000000",

    /**
     * "circle" || "rectangle"
     */
    shape: "circle",
    height: 20,
    width: 20,

    position: {
      /**
       * @type "TL" || "TR"
       *
       * @description
       * "TL" - Top Left
       * "TR" - Top Right
       */
      position: "TR",
    },

    ...MARGIN,
    margin: {
      ...MARGIN.margin,
      top: 12,
      bottom: 0,
      right: 16,
      left: 0,
    },
    ...BUTTON_ACTION,

    action: {
      ...BUTTON_ACTION.action,
      type: "close",
    },
  },
}

export const HEADER = {
  enable_header: true,
  header: {
    background_color: "#5965EC",
    ...STEPPER,
    stepper: {
      ...STEPPER.stepper,
      pagination_text: {
        ...STEPPER.stepper.pagination_text,
        font: {
          ...STEPPER.stepper.pagination_text.font,
          size: 12,
        },
        color: "#CFCFCF",
      },
    },
    ...BACK_BUTTON,
    ...CLOSE_BUTTON,
    ...TITLE,
    title: {
      ...TITLE.title,
      text: "Live Classes Feedback",
      color: "#FFFFFF",
      alignment: "center",
      font: {
        ...TITLE.title.font,
        size: 16,
        weight: "bold",
      },
      margin: {
        ...TITLE.title.margin,
        top: 12,
        bottom: 12,
        right: 16,
        left: 16,
      },
    },
    ...DESCRIPTION,
    description: {
      ...DESCRIPTION.description,
      text: "This survey will be anonymous and help in improving the live classes experience",
      color: "#FFFFFF",
      font: {
        ...DESCRIPTION.description.font,
        size: 12,
      },
      margin: {
        ...DESCRIPTION.description.margin,
        top: 0,
        bottom: 12,
        right: 16,
        left: 16,
      },
    },
    ...IMAGE,
    ...BORDER,
    ...GRADIENT,
    ...PADDING,
    ...MARGIN,
  },
}

export const FOOTER = {
  enable_footer: true,
  footer: {
    background_color: "#FFFFFF",

    /**
     * @type {Array<BUTTON>}
     */
    buttons: [],

    ...PADDING,
    ...MARGIN,
    ...BORDER,
    ...GRADIENT,
  },
}
export const PATHS = {}

export const QUESTION = {
  is_required: false,
  question: {
    ...TITLE,
    ...DESCRIPTION,
    ...CHOICE_PROMPT,
    /**
     * @type {PATHS}
     */
    paths: {},
    paths_v2: [],
  },
}

export const LIST_ITEM = {
  id: getQAId(),
  value: "",
  is_other: false,
  next_question_id: -1,
}

export const CHOICES = {
  min_choices_enabled: false,
  max_choices_enabled: false,
  min_choices: -1,
  max_choices: -1,
  randomize_choices: false,
  color_active: "#E5E5E5",
  color_inactive: "#002845",
  color_disable: "#002845",
  radio_color_active: "#E5E5E5",
  radio_color_inactive: "#002845",
  bg_color_active: "#5965EC",
  bg_color_inactive: "#E5E5E5",
  bg_color_disable: "#E5E5E5",

  /**
   * "normal" || "filled"
   */
  bg_type: "filled",

  ...BORDER,
  border: {
    ...BORDER.border,
    radius: 4,
  },

  /**
   * @type {Array<LIST_ITEM>}
   */
  list: [],
}

export const LABELS = {
  enable_labels: true,
  labels: {
    first_rating: {
      /**
       * "top" || "bottom"
       */
      position: "top",

      text: {
        ...GENERIC_TEXT,
        text: "Not Likely",
        color: "#B4B1B1",
        font: {
          ...GENERIC_TEXT.font,
          size: 12,
        },
        margin: {
          ...GENERIC_TEXT.margin,
          bottom: 5,
        },
      },
    },
    last_rating: {
      /**
       * "top" || "bottom"
       */
      position: "bottom",

      text: {
        ...GENERIC_TEXT,
        text: "Very Likely",
        color: "#B4B1B1",
        font: {
          ...GENERIC_TEXT.font,
          size: 12,
        },
        margin: {
          ...GENERIC_TEXT.margin,
          top: 5,
        },
      },
    },
  },
}

export const SINGLE_BUTTON = {
  color: "#000000",
  value: 0,
  emoji: "",
}

export const BOX_MODEL = {
  enable_box_model: false,
  box_model: {
    active_color: "#C9CFD5",
    inactive_color: "#8497A5",
  },
}

export const RATING_BUTTONS = {
  buttons: {
    start: 1,
    end: 5,

    /**
     * "numbered" || "radio" || "slider" || "emoji" || "star"
     */
    type: "numbered",
    split_line: false,
    slider_color: "#000000",
    bg_color_active: "#017DFD",
    bg_color_inactive: "#E5E5E5",
    color_active: "#E5E5E5",
    color_inactive: "#002845",
    height: 20,
    width: 20,
    enable_colors: false,

    /**
     * @type {Array<SINGLE_BUTTON>}
     */
    buttons: [],

    ...FONT,
    font: {
      ...FONT.font,
      size: 16,
    },

    /**
     * Only for "emoji" type
     */
    enable_fade: false, // 0.25 opacity on inactive, mutually exclusive with enable_shadow

    /**
     * Only for "emoji" type
     */
    ...SHADOW,

    ...BOX_MODEL,
    ...BORDER,
    ...PADDING,
    ...MARGIN,
    margin: {
      top: 3,
      bottom: 3,
      left: 3,
      right: 3,
    },
  },
}

export const SUGGESTION = {
  enable_suggestion: false,
  suggestion: {
    bg_color_active: "#017DFD",
    bg_color_inactive: "#E5E5E5",
    color_active: "#ffffff",
    color_inactive: "#002845",

    ...FONT,
    ...BORDER,
    ...PADDING,
    padding: {
      ...PADDING.padding,
      top: 8,
      bottom: 8,
      left: 12,
      right: 12,
    },
    ...MARGIN,
    margin: {
      ...MARGIN.margin,
      left: 16,
      right: 16,
      top: 4,
      bottom: 4,
    },

    /**
     * @type {Array<string>}
     */
    text: [],
  },
}

export const QUESTION_AND_SUGGESTION = {
  start: 1,
  end: 5,
  enable_question: true,
  ...QUESTION,
  enable_input_text: false,
  ...SUGGESTION,
}

export const REACTION = {
  start: 1,
  end: 5,
  enable_text: false,
  text: {
    ...GENERIC_TEXT,
    margin: {
      top: 12,
      bottom: 12,
      left: 16,
      right: 16,
    },
  },

  /**
   * "above_image" || "below_image" || "below_buttons"
   */
  text_position: "below_buttons",

  enable_image: false,
  image: {
    ...ICON.icon,
    aspect_ratio: {
      width: 1,
      height: 1,
    },
  },
}

export const RATING = {
  rating: {
    /**
     * @type {Array<REACTION>}
     */
    reactions: [],

    ...RATING_BUTTONS,
    ...LABELS,

    /**
     * @type {Array<QUESTION_AND_SUGGESTION>}
     */
    q_and_s: [],
  },
}

export const ANSWER = {
  answer: {
    answer_mode: "",
    choices: CHOICES,
    ...SCROLL,
    ...INPUT_TEXT,
    ...FONT,
    ...BORDER,
    ...MARGIN,
    margin: {
      ...MARGIN.margin,
      top: 2,
      bottom: 2,
      left: 16,
      right: 16,
    },
    ...PADDING,
    ...RATING,
  },
}

export const Q_AND_A = {
  ...QUESTION,
  ...ANSWER,
  ...FOOTER,
}

export const SURVEY = {
  survey: {
    background_color: "#FFFFFF",
    is_response_based_survey: false,
    ...CLOSE_BUTTON,
    enable_close_button: false,
    close_button: {
      ...CLOSE_BUTTON.close_button,
      color: "#5965EC",
    },

    /**
     * @type {Array<Q_AND_A>}
     */
    q_and_a: [],

    ...BORDER,
  },
}

export const DEFAULT_SURVEY_REQUEST_IMAGE = {
  path: "https://uploads-ssl.webflow.com/5f7ae188ef1b6b55e708dbdb/65819e7f6362ccf9068048fb_image_Survey.png",
}

export const SURVEY_REQUEST = {
  enable_survey_request: false,
  enable_survey_request_latest: false,
}

export const DEFAULT_SURVEY_REQUEST = {
  width: 70,
  is_fullscreen: false,
  background_color: "#FFFFFF",
  ...POSITION,
  position: {
    ...POSITION.position,
    position: "C",
  },
  ...CLOSE_BUTTON,
  close_button: {
    ...CLOSE_BUTTON.close_button,
    color: "#808080",
    position: {
      ...CLOSE_BUTTON.close_button.position,
      offset: {
        x: 12,
        y: 12,
      },
    },
  },
  ...ICON,
  icon: {
    ...ICON.icon,
    path: "https://uploads-ssl.webflow.com/5f7ae188ef1b6b55e708dbdb/65819e7f6362ccf9068048fb_image_Survey.png",
    width: 120,
    height: 120,
    alignment: "C",
    ...MARGIN,
    margin: {
      ...MARGIN.margin,
      top: 16,
      bottom: 16,
    },
    aspect_ratio: {
      width: 1,
      height: 1,
    },
  },
  ...{ enable_icon: true },
  ...TITLE,
  title: {
    ...TITLE.title,
    text: "Live Classes Feedback",
    color: "#474747",
    alignment: "center",
    font: {
      ...TITLE.title.font,
      size: 16,
      weight: "bold",
    },
    margin: {
      ...TITLE.title.margin,
      top: 12,
      bottom: 12,
      right: 16,
      left: 16,
    },
  },
  ...DESCRIPTION,
  description: {
    ...DESCRIPTION.description,
    text: "Did you like the feature? Please give us a feedback.",
    color: "#474747",
    alignment: "center",
    font: {
      ...DESCRIPTION.description.font,
      size: 12,
    },
    margin: {
      ...DESCRIPTION.description.margin,
      top: 12,
      bottom: 12,
      right: 16,
      left: 16,
    },
  },
  buttons: [],
  buttons_container: {
    background_color: "transparent",
    buttons_position: "R",
    buttons_direction: "H",
    buttons_justify: false,
    ...SURVEYREQUESTBUTTON,
    ...MARGIN,
    margin: {
      ...MARGIN.margin,
      top: 12,
      bottom: 12,
      left: 16,
      right: 16,
    },
    ...BORDER,
  },
  ...MARGIN,
  margin: {
    ...MARGIN.margin,
    top: 0,
    bottom: 0,
  },
  ...BORDER,
}

export const DEFAULT_SUCCESS_MESSAGE_IMAGE = {
  path: "https://uploads-ssl.webflow.com/5f7ae188ef1b6b55e708dbdb/65819fe46e128dea2b8f23db_image%201.png",
}

export const SUCCESS_MESSAGE = {
  enable_success_message: false,
  enable_success_message_latest: false,
}

export const DEFAULT_SUCCESS_MESSAGE = {
  width: 60,
  is_fullscreen: false,
  background_color: "#FFFFFF",
  ...POSITION,
  position: {
    ...POSITION.position,
    position: "C",
  },
  ...ICON,
  ...{ enable_icon: true },
  icon: {
    ...ICON.icon,
    path: "https://uploads-ssl.webflow.com/5f7ae188ef1b6b55e708dbdb/65819fe46e128dea2b8f23db_image%201.png",
    width: 87,
    alignment: "C",
    ...MARGIN,
    margin: {
      ...MARGIN.margin,
      top: 16,
      bottom: 16,
    },
    aspect_ratio: {
      width: 1,
      height: 1,
    },
  },
  ...TITLE,
  title: {
    ...TITLE.title,
    text: "Thank you!",
    color: "#474747",
    alignment: "center",
    font: {
      ...TITLE.title.font,
      size: 18,
      weight: "bold",
    },
    margin: {
      ...TITLE.title.margin,
      top: 12,
      bottom: 12,
      right: 16,
      left: 16,
    },
  },
  ...DESCRIPTION,
  description: {
    ...DESCRIPTION.description,
    text: "Your submission has been sent.",
    color: "#777676",
    alignment: "center",
    font: {
      ...DESCRIPTION.description.font,
      size: 12,
    },
    margin: {
      ...DESCRIPTION.description.margin,
      top: 12,
      bottom: 12,
      right: 16,
      left: 16,
    },
  },
  ...MARGIN,
  margin: {
    ...MARGIN.margin,
    top: 12,
    bottom: 12,
  },
  ...BORDER,
  duration: 2,
}

export const DEFAULT_SURVEY = {
  delay: 0,
  min_version: 200,
  width: 90,
  height: 100,
  is_fullscreen: false,
  background_color: "#FFFFFF",
  ...POSITION,
  position: {
    ...POSITION.position,

    /**
     * @type "C" || "B"
     *
     * @description
     * "C" - Center
     *
     * "B" - Footer
     */
    position: "C",
  },
  ...BORDER,
  ...GRADIENT,
  ...DIM_BACKGROUND,
  ...SURVEY_REQUEST,
  ...PROGRESS_BAR,
  ...HEADER,
  ...SURVEY,
  ...SUCCESS_MESSAGE,
}

export const EMOJI_ARRAY = [
  "🫥",
  "😡",
  "😖",
  "😣",
  "☹️",
  "😞",
  "😐",
  "🙂",
  "😃",
  "😄",
  "😍",
]

export const EMOJI_ARRAY_WITH_START_ZERO_AND_END_TEN = [
  "😡",
  "😖",
  "😣",
  "☹️",
  "😞",
  "😐",
  "🙂",
  "😀",
  "😃",
  "😄",
  "😍",
]

export const EMOJI_ARRAY_WITH_FIVE_EMOJI = ["🫥", "😡", "😞", "🙂", "😃", "😍"]
export const EMOJI_ARRAY_WITH_START_ZERO_AND_END_FIVE = [
  "😡",
  "😞",
  "😐",
  "🙂",
  "😃",
  "😍",
]

export const EMOJI_ARRAY_WITH_THREE_EMOJI = ["🫥", "😡", "🙂", "😍"]
export const EMOJI_ARRAY_WITH_START_ZERO_AND_END_THREE = [
  "😡",
  "😞",
  "🙂",
  "😍",
]
