import {
  Button,
  CardMedia,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemText,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core"
import { ChevronLeft, HelpOutline } from "@material-ui/icons"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone"
import RefreshIcon from "@material-ui/icons/Refresh"
import { Alert } from "@material-ui/lab"
import { IconArrowLeft, IconPlaylistX, IconScan } from "@tabler/icons-react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import domtoimage from "dom-to-image"
import { QRCodeSVG } from "qrcode.react"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { flushSync } from "react-dom"
import { useParams } from "react-router-dom"
import Select from "react-select"
import { toast } from "sonner"
import { isExperienceDashboard } from "../../../../../../../../config"
import { APP_PLATFORMS } from "../../../../../../../../constants"
import { useAuth } from "../../../../../../../../contexts/AuthContext"
import useApxorClient from "../../../../../../../../utils/use-apxor-client"
import {
  useDeepCompareCallback,
  useDeepCompareCallbackNoCheck,
  useDeepCompareEffectNoCheck,
} from "../../../../../../../../utils/use-deep-compare"
import { apxTheme } from "../../../../../../../ApplicationFrame/styling/CustomCSS"
import useUser from "../../../../../../../Profile/hooks/use-user"
import ActionsTextField from "../../../../../../../ReusableComponents/ActionsTextField"
import Autocomplete from "../../../../../../../ReusableComponents/Autocomplete"
import ColumnDiv from "../../../../../../../ReusableComponents/ColumnDiv"
import HStack from "../../../../../../../ReusableComponents/HStack"
import Loading from "../../../../../../../ReusableComponents/Loading"
import ModuleConfigSection from "../../../../../../../ReusableComponents/ModuleConfigSection"
import ModuleDivider from "../../../../../../../ReusableComponents/ModuleDivider"
import RowDiv from "../../../../../../../ReusableComponents/RowDiv"
import SegmentedControl from "../../../../../../../ReusableComponents/SegmentedControl"
import ThemedTooltip from "../../../../../../../ReusableComponents/ThemedTooltip"
import VStack from "../../../../../../../ReusableComponents/VStack"
import {
  getSignedURLToPost,
  getTestDeviceAdditionQRURL,
  getTestDevicesAPI,
  uploadImageUsingSignedURL,
} from "../../../../../../common/actions"
import DownloadApxorAppContent from "../../../../../../common/components/DownloadApxorAppContent"
import {
  IndicatorSeparator,
  Option,
} from "../../../../../../common/components/campaigncreator/components/context/components/targetaudience/components/selects/DidNotDoEventsComboBox"
import { CustomCheckbox } from "../../../../../../common/components/campaigncreator/components/settings/components/recurlimits/RecurAndLimits"
import {
  CAMPAIGN_TYPE_ENUM,
  SET_DEFAULT_TEST_DEVICES,
  SET_SELECTED_TEST_DEVICE,
} from "../../../../../../common/components/campaigncreator/constants"
import { useTracked as useCampaignCreatorTracked } from "../../../../../../common/components/campaigncreator/store"
import { IconImageComponent } from "../../../../../../common/utils"
import { PulseAnimatedCell } from "../../../../../dashboard/components/sales-sandbox/ExperienceDemoFinishStep"
import { FEATURES_LIST_ENUM } from "../../../../../settings/general/constants"
import mobile from "../../assets/mobile.png"
import {
  ACTION_CLASSES,
  CLEAR_VIEW_MAP,
  COACHMARK_LAYOUT_TYPES,
  PATH_OR_VIEW_ID,
  SCREEN_OR_ACTIVITY,
  SET_ACTIVITY,
  SET_IS_CONTENT_VIEW_SELECTION_MODE,
  SET_ORIENTATION,
  SET_SCREEN,
  SET_SHOW_QR_FOR_TEST_DEVICE_ADDITION,
  SET_SHOW_QR_IN_VIEW_ID_PATH_SELECTION,
  SET_SSE_DATA,
  TOOLTIP_LAYOUT_TYPES,
  WEB_LAUNCH_MODE,
} from "../../constants"
import { useTracked } from "../../store"
import { TARGET_CONFIG } from "../../templates/common"
import {
  checkIfViewContainsContentViews,
  findIfSelectedSelectorIsUnique,
  generateQRCodeURL,
  getAdvancedViewFindingConfig,
  getUI,
  openURLInNewTab,
} from "../../utils"
import { publishDesignForPreviewAPI } from "../actions"
import { ORIENTATION_ENUM } from "../simulator/utils"
import DesktopWebLaunchURL from "./DesktopWebLaunchURL"
import MobileWebLaunchURL from "./MobileWebLaunchURL"
import SSEDesignPreviewForWeb from "./SSEDesignPreviewForWeb"
import SSEDevicePreview from "./SSEDevicePreview"
import TreeView from "./TreeView"
import { useModuleConfigStyles } from "./utils"

const customStyles = {
  control: (base, state) => ({
    ...base,
    primary: "orange",
    "&:hover": { borderColor: "none" },
    width: 180,
    paddingInline: 6,
    paddingBlock: 0,
    fontWeight: 500,
    fontSize: 13,
    borderRadius: 8,
    border: "none",
    boxShadow: state.isFocused
      ? "0 0 0 1.5px rgba(1, 125, 253, 0.88)"
      : "0 0 0 1px #CCD4DA",
    backgroundColor: apxTheme.palette.common.white,
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
  }),
  "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
    backgroundColor: "transparent",
  },
  placeholder: (base) => ({
    ...base,
    color: "#AEBCC7",
    fontSize: 13,
    fontWeight: 600,
  }),
  menu: (base) => ({
    ...base,
    width: 180,
    borderRadius: 12,
    marginTop: 6,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(0, 40, 69, 1)",
    },
  }),
}

const StyledInput = withStyles((theme) => ({
  root: {
    fontWeight: 550,
    fontSize: 13,
    color: theme.palette.text.primary,
    borderRadius: 8,
    border: "1px solid #CCD4DA",
    paddingInline: 5,
    paddingBlock: 2,
    height: 40,
    backgroundColor: "#fff",
  },
  disabled: {
    borderRadius: 4,
    color: "rgba(0, 40, 69, 0.6)",
    backgroundColor: "#F2F3F3",
    cursor: "not-allowed",
    "&::placeholder": {
      opacity: 0.2,
    },
  },
  focused: {
    boxShadow: "0 0 0 1.5px rgba(1, 125, 253, 0.88)",
    borderColor: "transparent",
  },
}))(Input)

const steps = (showQR = false, platform = APP_PLATFORMS.android) => [
  "SELECT YOUR TEST DEVICE",
  ...(showQR && platform === APP_PLATFORMS.android
    ? ["CHOOSE SCREEN ON DEVICE", "SELECT UI ELEMENT FROM THE SCREEN"]
    : [
        "OPEN YOUR APPLICATION",
        "CHOOSE SCREEN ON DEVICE",
        "SELECT UI ELEMENT FROM THE SCREEN",
      ]),
]

const CONTENT_BY_NAMES_ENUM = {
  TEST_DEVICE_SELECTION: "TEST_DEVICE_SELECTION",
  QR: "QR",
  ENABLE_WIDGET: "ENABLE_WIDGET",
  SCREEN_OR_ACTIVITY: "SCREEN_OR_ACTIVITY",
  VIEW_ID: "VIEW_ID",
}

const helperText = ["", "", "", "Campaign will be displayed for the UI element"]

const webhelperText = ["", ""]

const useStyles = makeStyles((theme) => ({
  testButton: {
    textTransform: "none",
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.text.full,
    },
    padding: theme.spacing(2, 3),
  },
  completed: (props) => ({
    fontWeight: "800 !important",
    color: theme.palette.text.primary,
  }),
  active: (props) => ({
    fontWeight: "800 !important",
    color: `${theme.palette.primary.main} !important`,
  }),
  label: (props) => ({
    display: "flex",
    fontWeight: "700",
    fontSize: "13px !important",
    flexDirection: "column",
  }),
  root: (props) => ({
    display: "flex",
    alignItems: "unset !important",
  }),
  downloadBackButton: (props) => ({
    alignSelf: "flex-start",
    color: theme.palette.text.primary,
  }),
  disabledText: (props) => ({
    color: `${theme.palette.text.primary} !important`,
    width: "100%",
  }),
}))

let intervalHandler

export default function ViewIDPathSelection({
  props = null,
  bundle_id = "",
  handleClose,
  handleProceed,
  saveImages = true,
  allowWebViewCheck = true,
  isDynamicText = false,
  action_class,
  layout_type,
  isFromDynamicScript = false,
}) {
  const allowHTMLCheckbox = useMemo(() => {
    if (action_class === ACTION_CLASSES.TOOLTIP) {
      if (layout_type === TOOLTIP_LAYOUT_TYPES.DEFAULT) return true
    } else if (action_class === ACTION_CLASSES.COACHMARK) {
      if (layout_type === COACHMARK_LAYOUT_TYPES.DEFAULT) return true
    } else return false
  }, [action_class, layout_type])

  const { logEvent } = useApxorClient()

  const { appId } = useParams()

  const { currentRefinedUser, appFeaturesArr } = useUser()

  const auth = useAuth()
  const classes = useStyles()

  const moduleConfigClasses = useModuleConfigStyles()

  const [campaignCreatorState, campaignCreatorDispatch] =
    useCampaignCreatorTracked()

  const {
    config: {
      _id,
      testing: { selected = "", selectedLabel = "" },
      meta: {
        platform,
        experience_type = "",
        name,
        is_mweb = false,
        isExperiment,
      },
      uis = [],
    },
  } = campaignCreatorState

  const queryClient = useQueryClient()

  const test_devices =
    queryClient.getQueryData(["test-devices", auth, appId]) ?? []

  const { mutate } = useMutation({
    mutationFn: async () => {
      const res = await getTestDevicesAPI(
        auth,
        appId,
        null,
        isExperienceDashboard(),
      )
      return res.reverse()
    },
    mutationKey: ["test-devices", auth, appId],
    onSuccess: (data) => {
      queryClient.setQueryData(["test-devices", auth, appId], data)
    },
  })

  const {
    // The following are client-side variables for remembering the view details
    tag: tagFromProps = "",
    id: idFromProps = "",
    search_type: searchTypeFromProps = PATH_OR_VIEW_ID.VIEW_ID,
    view_id: viewIdFromProps = "",
    path: pathFromProps = "",
    view_additional_info = {},
    find_config = {},

    // The following are client-side variables for remembering if the user selected Screen or Activity
    screenOrActivity: screenOrActivityFromProps = SCREEN_OR_ACTIVITY.ACTIVITY,
    navigation: navigationFromProps = "",
    screen: screenFromProps = "",

    // The following are for remembering if the user selected WebView
    is_wv = false,
    wv_tag = "",
    set_font_to_scale: setFontToScale,
    url: urlFromProps = "",
    from_edit = false,

    // The following are for remembering the device info and views bounds
    view_bounds = {},
  } = props || {}

  const isWeb = useMemo(() => platform === APP_PLATFORMS.web, [platform])

  const [designPageState, designPageDispatch] = useTracked()
  const {
    orientation,
    currentImage,
    layout = [],
    isContentViewSelectionMode,
    showWebViewTemplates,
    showQRinViewIdPathSelection,
    showQRForTestDeviceAddition,
    currentStep,
  } = designPageState

  const ui = getUI(currentStep, uis, isExperiment)
  const currentViewBounds =
    uis.length > 0 && currentStep >= 0 ? ui?.ui?.view_bounds : {}

  const avfEnabled =
    appFeaturesArr?.includes(FEATURES_LIST_ENUM.NUDGES.children.AVT.value) &&
    currentRefinedUser?.flags?.includes(
      FEATURES_LIST_ENUM.NUDGES.children.AVT.value,
    )

  const captureScreenButtonEnabled =
    appFeaturesArr?.includes(
      FEATURES_LIST_ENUM.NUDGES.children.CAPTURE_SCREEN.value,
    ) &&
    currentRefinedUser?.flags?.includes(
      FEATURES_LIST_ENUM.NUDGES.children.CAPTURE_SCREEN.value,
    )

  const [activeStep, setActiveStep] = useState(
    !isWeb && selected !== "" ? 1 : from_edit ? 1 : 0,
  )

  const stepsByPlatform = useMemo(
    () =>
      isWeb
        ? [
            "Launch url to select UI element from your website",
            "Select UI Element",
          ]
        : steps(showQRinViewIdPathSelection, platform),
    [isWeb, showQRinViewIdPathSelection, platform],
  )

  const [selectedDevice, setSelectedDevice] = useState(
    isWeb && !from_edit
      ? {
          label: "",
          value: "",
        }
      : {
          label: selectedLabel,
          value: selected,
        },
  )

  // The following are client-side variables for remembering the view details
  const [view, setView] = useState({
    id: idFromProps,
    tag: tagFromProps,
    path: pathFromProps,
    search_type: searchTypeFromProps,
    view_id: viewIdFromProps,
  })
  const [viewAdditionalInfo, setViewAdditionalInfo] = useState(
    view_additional_info ?? {},
  )
  const [findConfig, setFindConfig] = useState(find_config ?? {})

  // The following are client-side variables for remembering if the user selected Screen or Activity
  const [screenOrActivity, setScreenOrActivity] = useState(
    screenOrActivityFromProps ?? SCREEN_OR_ACTIVITY.ACTIVITY,
  )
  const [navigation, setNavigation] = useState(navigationFromProps ?? "")
  const [selectedScreen, setSelectedScreen] = useState(
    screenFromProps || undefined,
  )

  // The following are for remembering if the user selected WebView
  const [isForWebView, setIsForWebView] = useState(is_wv ?? false)
  const [fit_to_scale, set_fit_to_scale] = useState(setFontToScale ?? false)
  const [webviewTag, setWebviewTag] = useState(wv_tag ?? "")
  const [url, setURL] = useState(urlFromProps || bundle_id)

  const [showTreeView, setShowTreeView] = useState(false)
  const [showDownloadContent, setShowDownloadContent] = useState(false)
  const [showTestDevicesSection, setShowTestDevicesSection] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [pending, setPending] = useState(false)
  const [ratio, setRatio] = useState(null)

  // The following are for remembering device info and view bounds
  const [viewBounds, setViewBounds] = useState(currentViewBounds)

  const [enableProceed, setEnableProceed] = useState(false)
  const [showContentBasedSelection, setShowContentBasedSelection] = useState(
    avfEnabled && find_config?.eval && find_config.nth_level_parent !== 0
      ? true
      : false,
  )

  // Final value of Activity
  const activity = useMemo(
    () =>
      screenOrActivity === SCREEN_OR_ACTIVITY.ACTIVITY
        ? navigation
        : selectedScreen,
    [navigation, screenOrActivity, selectedScreen],
  )

  const helper = useMemo(() => (isWeb ? webhelperText : helperText), [isWeb])

  const message = useRef({
    message: "",
    type: "info",
  })

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep >= stepsByPlatform?.length - 1) {
        return prevActiveStep
      }
      return prevActiveStep + 1
    })
  }, [stepsByPlatform?.length])

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep <= 0) {
        return prevActiveStep
      }
      if (prevActiveStep === 3) {
        setShowContentBasedSelection(false)
        designPageDispatch({
          type: SET_IS_CONTENT_VIEW_SELECTION_MODE,
          value: false,
        })
        designPageDispatch({
          type: CLEAR_VIEW_MAP,
        })
        setFindConfig({})
      }
      return prevActiveStep - 1
    })
  }, [designPageDispatch])

  const handleStep = useCallback(
    (step) => {
      if (selectedDevice.value === "" && activeStep === 0) {
        return
      }
      if (!navigation && !selectedScreen && step >= 3) {
        return
      }
      if (step !== stepsByPlatform?.length - 1) {
        setShowContentBasedSelection(false)
        designPageDispatch({
          type: SET_IS_CONTENT_VIEW_SELECTION_MODE,
          value: false,
        })
        designPageDispatch({
          type: CLEAR_VIEW_MAP,
        })
        setFindConfig({})
      }
      setActiveStep(step)
    },
    [
      activeStep,
      designPageDispatch,
      navigation,
      selectedDevice.value,
      selectedScreen,
      stepsByPlatform?.length,
    ],
  )

  const handleViewSelect = (view) => {
    const {
      id = "",
      tag = "",
      path = "",
      is_in_wv = false,
      wv_tag = "",
      view_additional_info = {},
      view_bounds = {},
    } = view

    //Step navigation when view_id is found
    if ((tag || id || path) && platform !== APP_PLATFORMS.web) {
      handleStep(stepsByPlatform?.length - 1)
    }

    if (is_in_wv && wv_tag) {
      // WebView Stuff
      setWebviewTag(wv_tag)
      setIsForWebView(true)
    } else {
      setIsForWebView(false)
    }

    if (platform !== APP_PLATFORMS.web) {
      if (!avfEnabled) {
        // View Bounds Info
        setViewBounds(view_bounds)

        // JetPack Compose Info
        setViewAdditionalInfo(view_additional_info)

        const type = tag || id ? PATH_OR_VIEW_ID.VIEW_ID : PATH_OR_VIEW_ID.PATH

        const isIdentifierUnique = findIfSelectedSelectorIsUnique({
          id,
          tag,
          path,
        })

        if (
          isIdentifierUnique === false &&
          (id !== "" || tag !== "" || path !== "")
        ) {
          message.current = {
            message:
              "The selected view is not unique, you might find the nudge on the first view that matches the identifier",
            type: "warning",
          }
        } else if (type === PATH_OR_VIEW_ID.PATH && path !== "") {
          message.current = {
            message:
              "Path tends to be unreliable due to its reliance on element order",
            type: "warning",
          }
        } else {
          message.current = {
            message: "",
            type: "info",
          }
        }

        // View Info
        setView({
          tag,
          id,
          view_id: tag || id || path,
          path,
          search_type:
            tag || id ? PATH_OR_VIEW_ID.VIEW_ID : PATH_OR_VIEW_ID.PATH,
        })
      } else {
        if (isContentViewSelectionMode) {
          const newFindConfig = getAdvancedViewFindingConfig(
            view,
            findConfig,
            true,
          )

          if (newFindConfig.eval.val) {
            message.current = {
              message: "View Identified Successfully!",
              type: "success",
            }
            flushSync(() => {
              setFindConfig(newFindConfig)
            })
            setEnableProceed(true)
          }
        } else {
          flushSync(() => {
            designPageDispatch({
              type: CLEAR_VIEW_MAP,
            })

            setShowContentBasedSelection(false)

            designPageDispatch({
              type: SET_IS_CONTENT_VIEW_SELECTION_MODE,
              value: false,
            })
          })

          // View Bounds Info
          setViewBounds(view_bounds)

          // JetPack Compose Info
          setViewAdditionalInfo(view_additional_info)

          // View Info
          setView({
            tag,
            id,
            view_id: tag || id || path,
            path,
            search_type:
              tag || id ? PATH_OR_VIEW_ID.VIEW_ID : PATH_OR_VIEW_ID.PATH,
          })

          const localFindConfig = getAdvancedViewFindingConfig(view)

          flushSync(() => {
            setFindConfig(localFindConfig)
          })

          if (view_additional_info?.content) {
            setShowContentBasedSelection(true)
          } else if (
            checkIfViewContainsContentViews(view, designPageDispatch)
          ) {
            setShowContentBasedSelection(true)
          }

          if (
            Object.keys(localFindConfig).length === 0 ||
            (localFindConfig?.search_criteria?.[0] === "path" &&
              !localFindConfig?.view_path) ||
            (localFindConfig?.search_criteria?.[0] === "id" &&
              !localFindConfig?.view_id)
          ) {
            message.current = {
              message: "Please select a view again",
              type: "warning",
            }
          } else if (localFindConfig?.search_criteria?.[0] === "path") {
            message.current = {
              message:
                "Path tends to be unreliable due to its reliance on element order",
              type: "warning",
            }
          } else {
            message.current = {
              message: "View Identified Successfully!",
              type: "success",
            }
          }
        }
      }
    } else {
      setView(view)
    }
  }

  const getContainerId = useCallback((index) => {
    switch (index) {
      case 0:
        return "ui-select-test-device"
      case 1:
        return "ui-container"
      case 2:
        return "ui-select-screen"
      case 3:
        return "ui-select-element"
      default:
        return "ui-container"
    }
  }, [])

  const getDeviceName = (deviceId, testDevices) => {
    const device = testDevices.find((item) => item.id === deviceId)
    if (device) {
      return `${
        device.hasOwnProperty("nick_name") && device.nick_name !== ""
          ? device.nick_name
          : device.id
      } - ${device.model}`
    }
    return ""
  }

  const handleTestDeviceSelection = (selectedDeviceId) => {
    setFetching(true)
    getTestDevicesAPI(auth, appId, null, isExperienceDashboard())
      .then((response) => {
        const reversed = response.reverse()
        campaignCreatorDispatch({
          type: SET_DEFAULT_TEST_DEVICES,
          devices: reversed,
        })
        const selectedDeviceInfo =
          reversed.length > 0
            ? {
                label: getDeviceName(selectedDeviceId, reversed),
                value: selectedDeviceId,
              }
            : { label: "", value: "" }

        setSelectedDevice(selectedDeviceInfo)
        campaignCreatorDispatch({
          type: SET_SELECTED_TEST_DEVICE,
          value: selectedDeviceInfo,
        })
        selectedDeviceId.length > 0 &&
          publishDesignForPreviewAPI(
            auth,
            auth.appId,
            {
              deviceId: selectedDeviceId,
            },
            {
              type: CAMPAIGN_TYPE_ENUM.IN_LINE,
              cmd: "show",
            },
          )
        setFetching(false)
      })
      .catch(() => {
        setFetching(false)
      })
  }

  const getStepContentByName = (name) => {
    switch (name) {
      case CONTENT_BY_NAMES_ENUM.TEST_DEVICE_SELECTION:
        return (
          <VStack
            style={{
              marginBottom: "2px",
            }}
          >
            <Autocomplete
              open={false}
              button={{
                label: "+ Register New Device",
                value: "+ Register New Device",
                isButton: true,
              }}
              defaultValue={{
                label: selectedLabel,
                value: selected,
              }}
              handleOnChange={(selectedValue) => {
                if (selectedValue && selectedValue.isButton) {
                  logEvent("UIElement_RegisterNewDevice", {
                    campaign_name: name,
                    campaign_id: _id,
                  })
                  setShowDownloadContent(true)
                  return
                }
                setSelectedDevice(selectedValue)
                logEvent("TestDeviceEntered", {
                  device_id: selectedValue,
                  experience_type: experience_type,
                })
                logEvent("UIElement_TestDeviceSelected", {
                  campaign_name: name,
                  campaign_id: _id,
                  test_device: selectedValue,
                })
                campaignCreatorDispatch({
                  type: SET_SELECTED_TEST_DEVICE,
                  value: selectedValue,
                })
                handleNext()
              }}
              options={test_devices.map((device) => ({
                label: `${
                  device.hasOwnProperty("nick_name")
                    ? device.nick_name || device.model
                    : device.model
                } - ${device.model}`,
                value: device.id,
              }))}
            />
          </VStack>
        )
      case CONTENT_BY_NAMES_ENUM.QR:
        return loading ? (
          <Loading />
        ) : qrCodeURL === "" ? (
          <VStack
            style={{ width: 225, height: 225 }}
            justify={"center"}
            align={"center"}
          >
            <Loading />
          </VStack>
        ) : (
          <QRCodeSVG
            value={qrCodeURL}
            height="225px"
            width="225px"
          />
        )
      case CONTENT_BY_NAMES_ENUM.ENABLE_WIDGET:
        return (
          <VStack
            align="start"
            style={{
              marginBottom: "2px",
            }}
          >
            <VStack spacing={10}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.testButton}
                disabled={!selectedDevice.value}
                startIcon={<RefreshIcon color="secondary" />}
                onClick={async () => {
                  logEvent("UIElement_EnableWidgetClicked", {
                    campaign_name: name,
                    campaign_id: _id,
                  })
                  await publishDesignForPreviewAPI(
                    auth,
                    auth.appId,
                    {
                      deviceId: selectedDevice.value,
                    },
                    {
                      type: CAMPAIGN_TYPE_ENUM.IN_LINE,
                      cmd: "show",
                    },
                  )
                  handleNext()
                }}
              >
                Enable Widget
              </Button>
              {captureScreenButtonEnabled && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.testButton}
                  disabled={!selectedDevice.value}
                  startIcon={<PhoneIphoneIcon color="secondary" />}
                  onClick={async () => {
                    logEvent("UIElement_CaptureScreenClicked", {
                      campaign_name: name,
                      campaign_id: _id,
                    })
                    await publishDesignForPreviewAPI(
                      auth,
                      auth.appId,
                      {
                        deviceId: selectedDevice.value,
                      },
                      {
                        type: CAMPAIGN_TYPE_ENUM.IN_LINE,
                        cmd: "capt",
                      },
                    )
                    handleNext()
                  }}
                >
                  Capture Screen
                </Button>
              )}
            </VStack>
          </VStack>
        )
      case CONTENT_BY_NAMES_ENUM.SCREEN_OR_ACTIVITY:
        return (
          <VStack
            style={{
              marginBottom: "2px",
            }}
          >
            <CardMedia
              autoPlay
              style={{
                marginLeft: "-8px",
                height: 540,
                width: 260,
                marginBottom: 20,
              }}
              title="Screen Selection walkthrough"
              component={"video"}
              controls
              muted
              loop
              image={
                "https://storage.googleapis.com/apx_frontend-assets/Screen_2.mp4"
              }
            ></CardMedia>

            <HStack
              justify="space-between"
              align="start"
              style={{
                width: "100%",
              }}
            >
              {!isForWebView && (
                <VStack spacing={4}>
                  <Typography
                    component="label"
                    color="textPrimary"
                    style={{
                      fontWeight: 600,
                      fontSize: 12,
                    }}
                  >
                    Type
                  </Typography>
                  <SegmentedControl
                    value={screenOrActivity}
                    onChange={(value) => {
                      setScreenOrActivity(value)
                    }}
                    items={[
                      {
                        name: "Screen",
                        value: SCREEN_OR_ACTIVITY.SCREEN,
                      },
                      {
                        name: "Activity",
                        value: SCREEN_OR_ACTIVITY.ACTIVITY,
                      },
                    ]}
                  />
                </VStack>
              )}

              <VStack spacing={4}>
                <Typography
                  component="label"
                  htmlFor="type"
                  color="textPrimary"
                  style={{
                    fontWeight: 600,
                    fontSize: 12,
                    display: "flex",
                    alignItems: "center",
                    gap: 3.5,
                  }}
                >
                  <span>Screen / Activity</span>
                  <ThemedTooltip
                    dark
                    title={
                      "Your screen will automatically populate here once you select screen in the helper app overlay"
                    }
                    placement="right"
                  >
                    <HelpOutline
                      style={{
                        fontSize: 14.5,
                        color: apxTheme.palette.text.primary,
                      }}
                    />
                  </ThemedTooltip>
                </Typography>
                <StyledInput
                  id="type"
                  placeholder={
                    screenOrActivity === SCREEN_OR_ACTIVITY.SCREEN
                      ? "Screen Name"
                      : "Activity Name"
                  }
                  value={
                    screenOrActivity === SCREEN_OR_ACTIVITY.SCREEN
                      ? selectedScreen
                      : navigation
                  }
                  onChange={(e) => {
                    if (screenOrActivity === SCREEN_OR_ACTIVITY.SCREEN) {
                      setSelectedScreen(e.target.value)
                    } else {
                      setNavigation(e.target.value)
                    }
                  }}
                />
              </VStack>
            </HStack>
          </VStack>
        )
      case CONTENT_BY_NAMES_ENUM.VIEW_ID:
        return (
          <VStack style={{ marginBottom: 64 }}>
            <ColumnDiv>
              {/* Written in HTML Checkbox */}
              {!showWebViewTemplates && allowHTMLCheckbox && (
                <FormControlLabel
                  label={
                    <Typography
                      component="label"
                      htmlFor="written-in-html"
                      color="textPrimary"
                      style={{
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      Is this screen written in HTML?
                    </Typography>
                  }
                  control={
                    <CustomCheckbox
                      id="written-in-html"
                      checked={isForWebView}
                      size="small"
                      color="primary"
                      onChange={(_e, checked) => {
                        setIsForWebView(checked)
                        if (!checked) {
                          set_fit_to_scale(false)
                        }
                      }}
                    />
                  }
                />
              )}

              {/* Fit to Scale Checkbox */}
              {(isForWebView || showWebViewTemplates) && (
                <FormControlLabel
                  label={
                    <Typography
                      component="label"
                      htmlFor="fit-to-scale"
                      color="textPrimary"
                      style={{
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      Fit to Scale
                    </Typography>
                  }
                  control={
                    <CustomCheckbox
                      id="fit-to-scale"
                      checked={fit_to_scale}
                      defaultChecked={fit_to_scale}
                      value={fit_to_scale}
                      defaultValue={fit_to_scale}
                      size="small"
                      color="primary"
                      onChange={(_e, checked) => {
                        set_fit_to_scale(checked)
                      }}
                    />
                  }
                />
              )}

              {avfEnabled && (
                <>
                  {showContentBasedSelection ? (
                    <>
                      {/* Content Based Selection */}
                      <FormControlLabel
                        label={
                          <Typography
                            component="label"
                            htmlFor="content-view-selection"
                            color="textPrimary"
                            style={{
                              fontSize: 13,
                              fontWeight: 600,
                            }}
                          >
                            Proceed with Content View Selection
                          </Typography>
                        }
                        control={
                          <CustomCheckbox
                            id="content-view-selection"
                            checked={isContentViewSelectionMode}
                            size="small"
                            color="primary"
                            onChange={(_e, checked) => {
                              flushSync(() => {
                                checkIfViewContainsContentViews(
                                  {
                                    ...view,
                                    additional_info: viewAdditionalInfo,
                                  },
                                  designPageDispatch,
                                )
                              })
                              designPageDispatch({
                                type: SET_IS_CONTENT_VIEW_SELECTION_MODE,
                                value: checked,
                              })

                              if (viewAdditionalInfo?.content) {
                                setFindConfig(
                                  getAdvancedViewFindingConfig(
                                    {
                                      ...view,
                                      additional_info: viewAdditionalInfo,
                                    },
                                    findConfig,
                                    true,
                                  ),
                                )
                                message.current = {
                                  message: "View Identified Successfully",
                                  type: "success",
                                }
                              } else {
                                message.current = {
                                  message: "Please select an inner view now",
                                  type: "info",
                                }
                              }

                              if (!checked) {
                                if (viewAdditionalInfo?.content) {
                                  setFindConfig(
                                    getAdvancedViewFindingConfig(
                                      {
                                        ...view,
                                        additional_info: viewAdditionalInfo,
                                      },
                                      findConfig,
                                      false,
                                    ),
                                  )
                                } else {
                                  setShowContentBasedSelection(false)
                                  handleResetState()
                                  message.current = {
                                    message: "Please select a view again",
                                    type: "warning",
                                  }
                                }
                              }
                            }}
                          />
                        }
                      />

                      {isContentViewSelectionMode && (
                        <HStack spacing={24}>
                          {/* Operator */}
                          <VStack spacing={4}>
                            <Typography
                              component="label"
                              htmlFor="operator"
                              color="textPrimary"
                              style={{
                                fontWeight: 600,
                                fontSize: 12,
                              }}
                            >
                              Operator
                            </Typography>
                            <Select
                              options={[
                                {
                                  label: "Equals",
                                  value: "EQ",
                                },
                                {
                                  label: "Contains",
                                  value: "CONTAINS",
                                },
                              ]}
                              value={{
                                label: findConfig?.eval?.op ?? "Equals",
                                value: findConfig?.eval?.op ?? "EQ",
                              }}
                              onChange={(pair) => {
                                setFindConfig((prevFindConfig) => ({
                                  ...prevFindConfig,
                                  eval: {
                                    ...prevFindConfig?.eval,
                                    op: pair.value,
                                  },
                                }))
                              }}
                              inputId="operator"
                              placeholder="Select Operator"
                              isSearchable={false}
                              isClearable={false}
                              isMulti={false}
                              closeMenuOnSelect={true}
                              styles={customStyles}
                              maxMenuHeight={150}
                              components={{
                                Option: Option,
                                IndicatorSeparator: IndicatorSeparator,
                              }}
                              isDisabled={!findConfig?.eval}
                            />
                          </VStack>

                          {/* Value */}
                          <VStack spacing={4}>
                            <Typography
                              component="label"
                              htmlFor="value"
                              color="textPrimary"
                              style={{
                                fontWeight: 600,
                                fontSize: 12,
                              }}
                            >
                              Value
                            </Typography>
                            <StyledInput
                              onChange={(e) => {
                                const value = e.target.value
                                setFindConfig((prevFindConfig) => ({
                                  ...prevFindConfig,
                                  eval: {
                                    ...prevFindConfig?.eval,
                                    val: value,
                                  },
                                }))
                              }}
                              id="value"
                              value={findConfig?.eval?.val ?? ""}
                              style={{
                                borderRadius: 8,
                                border: "1px solid #CCD4DA",
                                paddingBlock: 2,
                                maxHeight: 40,
                                backgroundColor: "#fff",
                              }}
                              disabled={!findConfig?.eval}
                            />
                          </VStack>
                        </HStack>
                      )}
                    </>
                  ) : (
                    <>
                      {findConfig?.eval && (
                        <HStack spacing={24}>
                          {/* Operator */}
                          <VStack spacing={4}>
                            <Typography
                              component="label"
                              htmlFor="operator"
                              color="textPrimary"
                              style={{
                                fontWeight: 600,
                                fontSize: 12,
                              }}
                            >
                              Operator
                            </Typography>
                            <Select
                              options={[
                                {
                                  label: "Equals",
                                  value: "EQ",
                                },
                                {
                                  label: "Contains",
                                  value: "CONTAINS",
                                },
                              ]}
                              value={{
                                label: findConfig?.eval?.op ?? "Equals",
                                value: findConfig?.eval?.op ?? "EQ",
                              }}
                              onChange={(pair) => {
                                setFindConfig((prevFindConfig) => ({
                                  ...prevFindConfig,
                                  eval: {
                                    ...prevFindConfig?.eval,
                                    op: pair.value,
                                  },
                                }))
                              }}
                              inputId="operator"
                              placeholder="Select Operator"
                              isSearchable={false}
                              isClearable={false}
                              isMulti={false}
                              closeMenuOnSelect={true}
                              styles={customStyles}
                              maxMenuHeight={150}
                              components={{
                                Option: Option,
                                IndicatorSeparator: IndicatorSeparator,
                              }}
                              isDisabled={!findConfig?.eval}
                            />
                          </VStack>

                          {/* Value */}
                          <VStack spacing={4}>
                            <Typography
                              component="label"
                              htmlFor="value"
                              color="textPrimary"
                              style={{
                                fontWeight: 600,
                                fontSize: 12,
                              }}
                            >
                              Value
                            </Typography>
                            <StyledInput
                              onChange={(e) => {
                                const value = e.target.value
                                setFindConfig((prevFindConfig) => ({
                                  ...prevFindConfig,
                                  eval: {
                                    ...prevFindConfig?.eval,
                                    val: value,
                                  },
                                }))
                              }}
                              id="value"
                              value={findConfig?.eval?.val ?? ""}
                              style={{
                                borderRadius: 8,
                                border: "1px solid #CCD4DA",
                                paddingBlock: 2,
                                maxHeight: 40,
                                backgroundColor: "#fff",
                              }}
                              disabled={!findConfig?.eval}
                            />
                          </VStack>
                        </HStack>
                      )}
                    </>
                  )}
                </>
              )}

              {/* Message */}
              {avfEnabled && message.current.message !== "" && (
                <PulseAnimatedCell
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    color: "#fff",
                    backgroundColor:
                      apxTheme.palette[message.current.type]?.main,
                    minWidth: "60%",
                    borderRadius: 8,
                    textAlign: "center",
                    marginTop: 24,
                    width: "100%",
                  }}
                >
                  {message.current.message}
                </PulseAnimatedCell>
              )}

              {!avfEnabled && (
                <>
                  {!isForWebView && !showWebViewTemplates && (
                    <HStack
                      justify="space-between"
                      align="start"
                      style={{
                        width: "100%",
                      }}
                    >
                      <VStack spacing={4}>
                        <Typography
                          component="label"
                          color="textPrimary"
                          style={{
                            fontWeight: 600,
                            fontSize: 12,
                          }}
                        >
                          Type
                        </Typography>
                        <SegmentedControl
                          value={view.search_type}
                          onChange={(value) => {
                            setView((prevView) => ({
                              ...prevView,
                              search_type: value,
                              view_id:
                                value === PATH_OR_VIEW_ID.PATH
                                  ? prevView.path
                                  : prevView.id,
                            }))
                            if (!avfEnabled) {
                              const isIdentifierUnique =
                                findIfSelectedSelectorIsUnique({
                                  id: view.id,
                                  tag: view.tag,
                                  path: view.path,
                                })

                              if (
                                value === PATH_OR_VIEW_ID.PATH &&
                                view.path !== ""
                              ) {
                                message.current = {
                                  message:
                                    "Path tends to be unreliable due to its reliance on element order",
                                  type: "warning",
                                }
                              } else if (
                                isIdentifierUnique === false &&
                                (view.id !== "" ||
                                  view.tag !== "" ||
                                  view.path !== "")
                              ) {
                                message.current = {
                                  message:
                                    "The selected view is not unique, you might find the nudge on the first view that matches the identifier",
                                  type: "warning",
                                }
                              } else {
                                message.current = {
                                  message: "",
                                  type: "info",
                                }
                              }
                            }
                          }}
                          items={[
                            {
                              name: "Path",
                              value: PATH_OR_VIEW_ID.PATH,
                            },
                            {
                              name: "ID/Tag",
                              value: PATH_OR_VIEW_ID.VIEW_ID,
                            },
                          ]}
                        />
                      </VStack>

                      <VStack spacing={4}>
                        <Typography
                          component="label"
                          htmlFor="type"
                          color="textPrimary"
                          style={{
                            fontWeight: 600,
                            fontSize: 12,
                          }}
                        >
                          View ID / Tag / Path
                        </Typography>
                        <StyledInput
                          id="type"
                          placeholder={
                            view.search_type === PATH_OR_VIEW_ID.VIEW_ID
                              ? "View ID / Tag"
                              : "Path"
                          }
                          value={
                            view.search_type === PATH_OR_VIEW_ID.VIEW_ID
                              ? view.tag === "" && view.id !== ""
                                ? view.id
                                : view.tag
                              : view.path
                          }
                          onChange={(e) => {
                            if (view.search_type === PATH_OR_VIEW_ID.VIEW_ID) {
                              if (view.tag !== "") {
                                setView((prevView) => ({
                                  ...prevView,
                                  tag: e.target.value,
                                  view_id: e.target.value,
                                }))
                              } else {
                                setView((prevView) => ({
                                  ...prevView,
                                  id: e.target.value,
                                  view_id: e.target.value,
                                }))
                              }
                            } else {
                              setView((prevView) => ({
                                ...prevView,
                                path: e.target.value,
                                view_id: e.target.value,
                              }))
                            }
                          }}
                        />
                      </VStack>
                    </HStack>
                  )}

                  {(isForWebView || showWebViewTemplates) && (
                    <HStack spacing={24}>
                      {/* WebView Tag */}
                      <VStack spacing={4}>
                        <Typography
                          component="label"
                          htmlFor="webview-tag"
                          color="textPrimary"
                          style={{
                            fontWeight: 600,
                            fontSize: 12,
                          }}
                        >
                          WebView Tag
                        </Typography>
                        <StyledInput
                          id="webview-tag"
                          placeholder="Web View Tag"
                          value={webviewTag}
                          onChange={(e) => {
                            const value = e.target.value
                            setWebviewTag(value)
                          }}
                          style={{
                            borderRadius: 8,
                            border: "1px solid #CCD4DA",
                            paddingBlock: 2,
                            maxHeight: 40,
                            backgroundColor: "#fff",
                          }}
                        />
                      </VStack>

                      {/* XPath/CSS Selector */}
                      <VStack spacing={4}>
                        <Typography
                          component="label"
                          htmlFor="css-selector"
                          color="textPrimary"
                          style={{
                            fontWeight: 600,
                            fontSize: 12,
                          }}
                        >
                          Element Identifier
                        </Typography>
                        <StyledInput
                          id="css-selector"
                          placeholder="Element Identifier"
                          value={view.id}
                          onChange={(e) => {
                            const value = e.target.value
                            setView({
                              ...view,
                              id: value,
                              view_id: value,
                            })
                          }}
                          style={{
                            borderRadius: 8,
                            border: "1px solid #CCD4DA",
                            paddingBlock: 2,
                            maxHeight: 40,
                            backgroundColor: "#fff",
                          }}
                        />
                      </VStack>
                    </HStack>
                  )}

                  {/* Message */}
                  {!avfEnabled && message.current.message !== "" && (
                    <PulseAnimatedCell
                      style={{
                        fontSize: 12,
                        fontWeight: 600,
                        color: "#fff",
                        backgroundColor:
                          apxTheme.palette[message.current.type]?.main,
                        minWidth: "60%",
                        width:
                          message.current.type === "warning" ? "100%" : "60%",
                        borderRadius: 8,
                        textAlign:
                          message.current.type === "warning"
                            ? "left"
                            : "center",
                        marginTop: 24,
                      }}
                    >
                      {message.current.message}
                    </PulseAnimatedCell>
                  )}
                </>
              )}

              {avfEnabled && (
                <HStack
                  justify="space-between"
                  align="start"
                  style={{
                    width: "100%",
                  }}
                >
                  <Button
                    size="small"
                    id="ui-reset-button"
                    variant="outlined"
                    color="primary"
                    onClick={() => handleResetState()}
                    style={{ marginTop: "24px" }}
                    startIcon={<IconPlaylistX />}
                  >
                    Reset
                  </Button>
                </HStack>
              )}
            </ColumnDiv>
          </VStack>
        )
      default:
        return <></>
    }
  }

  const getStepContent = (index) => {
    switch (index) {
      case 0:
        return showQRinViewIdPathSelection
          ? getStepContentByName(CONTENT_BY_NAMES_ENUM.QR)
          : getStepContentByName(CONTENT_BY_NAMES_ENUM.TEST_DEVICE_SELECTION)
      case 1:
        return showQRinViewIdPathSelection && platform === APP_PLATFORMS.android
          ? getStepContentByName(CONTENT_BY_NAMES_ENUM.SCREEN_OR_ACTIVITY)
          : getStepContentByName(CONTENT_BY_NAMES_ENUM.ENABLE_WIDGET)
      case 2:
        return showQRinViewIdPathSelection && platform === APP_PLATFORMS.android
          ? getStepContentByName(CONTENT_BY_NAMES_ENUM.VIEW_ID)
          : getStepContentByName(CONTENT_BY_NAMES_ENUM.SCREEN_OR_ACTIVITY)
      case 3:
        return getStepContentByName(CONTENT_BY_NAMES_ENUM.VIEW_ID)
      default:
        break
    }
  }

  const newVolatileID = useMemo(
    () => Math.random().toString(36).substring(2, 15),
    [],
  )

  const getContentForWeb = useCallback(
    (index) => {
      switch (index) {
        case 0:
          return is_mweb ? (
            <>
              <MobileWebLaunchURL
                defaultUrl={bundle_id}
                url={url}
                setURL={setURL}
                generateQRCodeCallback={() => {
                  const urlWithParams = generateQRCodeURL(
                    url,
                    appId,
                    selected === "" ? newVolatileID : selected,
                    WEB_LAUNCH_MODE.VIEW_SELECTION,
                    !avfEnabled ? "v1" : "v2",
                  )
                  handleNext()
                  return urlWithParams
                }}
              />
            </>
          ) : (
            <>
              <DesktopWebLaunchURL
                defaultUrl={bundle_id}
                url={url}
                setURL={setURL}
                launchCallback={() => {
                  openURLInNewTab(
                    url,
                    appId,
                    selected === "" ? newVolatileID : selected,
                    WEB_LAUNCH_MODE.VIEW_SELECTION,
                    !avfEnabled ? "v1" : "v2",
                  )
                  handleNext()
                }}
              />
            </>
          )
        case 1:
          return (
            <RowDiv
              center
              styles={{
                gap: 10,
                marginTop: 10,
              }}
            >
              <ModuleConfigSection
                title="Element Identifier"
                style={{
                  marginTop: 0,
                  alignItems: "center",
                  gap: 2,
                }}
                typographyClassName={moduleConfigClasses.moduleConfigRoot}
              >
                <ActionsTextField
                  style={{ marginBottom: 0 }}
                  placeholder="Element Identifier"
                  value={view.id}
                  multiline={false}
                  onChange={(e) => {
                    const value = e.target.value
                    setView((prevView) => ({
                      ...prevView,
                      id: value,
                    }))
                  }}
                />
              </ModuleConfigSection>
            </RowDiv>
          )
        default:
          break
      }
    },
    [
      appId,
      bundle_id,
      avfEnabled,
      handleNext,
      is_mweb,
      moduleConfigClasses.moduleConfigRoot,
      newVolatileID,
      selected,
      url,
      view.id,
    ],
  )

  const recursiveSearchForViewBounds = useDeepCompareCallbackNoCheck(
    (layout) => {
      const viewBounds =
        layout?.length > 0
          ? layout.map(({ id, path, views, bounds }) =>
              view.view_id === id || view.view_id === path
                ? bounds
                : views.length > 0
                  ? recursiveSearchForViewBounds(views)
                  : {},
            )
          : []
      return (
        viewBounds?.filter((obj) => Object.keys(obj).length !== 0)?.[0] ?? {}
      )
    },
    [layout, view.view_id],
  )

  const handleSaveAndProceed = useDeepCompareCallback(() => {
    logEvent("UIElement_ProceedClicked", {
      campaign_name: name,
      campaign_id: _id,
    })
    logEvent("ProceedClicked", { experience_type })

    setViewBounds(viewBounds)

    if (!isWeb && saveImages && _id) {
      setPending(true)
      getSignedURLToPost(auth, appId, {
        campaignId: _id,
        appId,
      }).then((response) => {
        const { signed_url, blob_name, provider } = response
        domtoimage
          .toBlob(document.querySelector("#apx_screen_preview"), {
            quality: 0.5,
          })
          .then(function (blob) {
            const file = new File([blob], "image.png")
            uploadImageUsingSignedURL(
              provider,
              signed_url,
              file,
              () => {
                handleProceed({
                  // The following are client-side variables for remembering if the user wanted ViewID or Path
                  tag: view?.tag ?? "",
                  id: view?.id ?? "",
                  view_id: view?.view_id || "",
                  path: view?.path ?? "",
                  search_type: view?.search_type ?? PATH_OR_VIEW_ID.VIEW_ID,
                  view_additional_info: viewAdditionalInfo,
                  target: {
                    ...TARGET_CONFIG.target,
                    id:
                      view.search_type === PATH_OR_VIEW_ID.VIEW_ID
                        ? view.view_id
                        : view.path,
                    is_content_based: view?.is_content_based ?? false,
                    content: view?.content ?? {},
                  },

                  // Advanced View Finding only if avfEnabled
                  ...(avfEnabled && {
                    find_config: findConfig,
                  }),

                  // The following are client-side variables for remembering if the user selected Screen or Activity
                  screenOrActivity,
                  navigation,
                  screen: selectedScreen,
                  activity,
                  url,

                  // The following are for remembering if the user selected WebView
                  isForWebView,
                  fit_to_scale,
                  webviewTag,

                  // The following are for remembering the device info and views bounds
                  view_bounds: viewBounds,

                  blob_name,
                  provider,
                  screenshot: () =>
                    domtoimage.toPng(
                      document.querySelector("#apx_screen_preview"),
                      {
                        quality: 0.5,
                      },
                    ),
                })
              },
              () => {
                handleProceed({
                  // The following are client-side variables for remembering if the user wanted ViewID or Path
                  tag: view?.tag ?? "",
                  id: view?.id ?? "",
                  view_id: view?.view_id || "",
                  path: view?.path ?? "",
                  search_type: view?.search_type ?? PATH_OR_VIEW_ID.VIEW_ID,
                  view_additional_info: viewAdditionalInfo,
                  target: {
                    ...TARGET_CONFIG.target,
                    id:
                      view.search_type === PATH_OR_VIEW_ID.VIEW_ID
                        ? view.view_id
                        : view.path,
                    is_content_based: view?.is_content_based ?? false,
                    content: view?.content ?? {},
                  },

                  // Advanced View Finding only if avfEnabled
                  ...(avfEnabled && {
                    find_config: findConfig,
                  }),

                  // The following are client-side variables for remembering if the user selected Screen or Activity
                  screenOrActivity,
                  navigation,
                  screen: selectedScreen,
                  activity,
                  url,

                  // The following are for remembering if the user selected WebView
                  isForWebView,
                  fit_to_scale,
                  webviewTag,

                  // The following are for remembering the device info and views bounds
                  view_bounds: viewBounds,

                  blob_name,
                  provider,
                  screenshot: () =>
                    domtoimage.toPng(
                      document.querySelector("#apx_screen_preview"),
                      {
                        quality: 0.5,
                      },
                    ),
                })
                toast.error("Failed to upload image")
              },
            )
          })
          .catch(function (error) {
            setPending(false)
            console.error("oops, something went wrong!", error)
          })
      })
      return
    }

    const data = {
      // The following are client-side variables for remembering if the user wanted ViewID or Path
      id: view?.id ?? "",
      tag: view?.tag ?? "",
      path: view?.path ?? "",
      view_id: view?.view_id || "",
      search_type: view?.search_type ?? PATH_OR_VIEW_ID.VIEW_ID,
      target: {
        ...TARGET_CONFIG.target,
        id:
          view?.search_type !== undefined
            ? view.search_type === PATH_OR_VIEW_ID.VIEW_ID
              ? view.view_id
              : view.path
            : view.id,
        is_content_based: view?.is_content_based ?? false,
        content: view?.content ?? {},
      },
      viewAdditionalInfo,

      // Advanced View Finding only if avfEnabled
      ...(avfEnabled && {
        find_config: findConfig,
      }),

      // The following are client-side variables for remembering if the user selected Screen or Activity
      url,
      screenOrActivity,
      navigation,
      screen: selectedScreen,
      activity,

      // The following are for remembering if the user selected WebView
      isForWebView,
      fit_to_scale,
      webviewTag,

      // The following are for remembering the device info and views bounds
      view_bounds,
    }
    if (isWeb) {
      data.webviewTag = ""
      data.activity = ""
      data.provider = ""
      data.blob_name = ""
    }
    handleProceed(data)
  }, [
    _id,
    activity,
    appId,
    auth,
    experience_type,
    fit_to_scale,
    handleProceed,
    isForWebView,
    isWeb,
    navigation,
    saveImages,
    findConfig,
    screenOrActivity,
    selectedScreen,
    webviewTag,
    layout,
    view,
    url,
  ])

  const handleResetState = () => {
    setView({
      id: "",
      tag: "",
      path: "",
      search_type: PATH_OR_VIEW_ID.VIEW_ID,
      view_id: "",
    })
    setEnableProceed(false)
    setFindConfig({})
    setViewBounds({})
    setViewAdditionalInfo({})
    designPageDispatch({
      type: SET_IS_CONTENT_VIEW_SELECTION_MODE,
      value: false,
    })
    designPageDispatch({
      type: CLEAR_VIEW_MAP,
    })
    message.current = {
      message: "",
      type: "",
    }
  }

  const backButton = (
    <>
      {isDynamicText && (
        <RowDiv
          center
          styles={{
            marginTop: -16,
            marginLeft: -16,
            marginBottom: 10,
          }}
        >
          {platform !== APP_PLATFORMS.web && (
            <IconButton
              className={classes.backButton}
              onClick={(e) => {
                e.stopPropagation()
                if (showTreeView) setShowTreeView(false)
                else handleClose()
              }}
            >
              <ChevronLeft
                color="action"
                style={{ color: "#002845" }}
              />
            </IconButton>
          )}
          <Typography
            variant="h5"
            color="textPrimary"
            style={{ fontWeight: 900 }}
          >
            Select UI Element{" "}
            <ThemedTooltip
              title="Your campaign will be shown on that UI element"
              placement="right"
              dark
            >
              <HelpOutline style={{ fontSize: 14 }} />
            </ThemedTooltip>
          </Typography>
        </RowDiv>
      )}
    </>
  )

  const fetchTestDevices = useDeepCompareCallback(() => {
    setFetching(true)
    getTestDevicesAPI(auth, appId, null, isExperienceDashboard())
      .then((response) => {
        const reversed = response.reverse()
        campaignCreatorDispatch({
          type: SET_DEFAULT_TEST_DEVICES,
          devices: reversed,
        })
        setFetching(false)
      })
      .catch(() => {
        setFetching(false)
      })
  }, [auth, campaignCreatorDispatch])

  useEffect(() => {
    if (intervalHandler) clearInterval(intervalHandler)
    if (showTestDevicesSection) {
      intervalHandler = setInterval(() => {
        fetchTestDevices()
      }, 5000)
    }

    return () => {
      if (intervalHandler) clearInterval(intervalHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTestDevicesSection])

  const getLabelDescription = useCallback(
    (step) => {
      switch (step) {
        case 0:
          return selectedDevice?.label ? (
            <span style={{ fontWeight: "500" }}>
              Selected Device : <b>{selectedDevice?.label}</b>
            </span>
          ) : (
            ""
          )
        case 1:
          return platform !== APP_PLATFORMS.web
            ? "Please make sure you open the Application in your test device before enabling widget"
            : ""
        case 2:
          return navigation ? (
            <>
              Selected{" "}
              {screenOrActivity === SCREEN_OR_ACTIVITY.ACTIVITY ? (
                <>
                  Activity : <b>{navigation}</b>
                </>
              ) : (
                <>
                  Screen : <b>{selectedScreen}</b>
                </>
              )}
            </>
          ) : (
            ""
          )
        default:
          return ""
      }
    },
    [
      navigation,
      platform,
      screenOrActivity,
      selectedDevice?.label,
      selectedScreen,
    ],
  )

  const continueBtnDisabled = (() => {
    if (activeStep === 0 || activeStep === 1) {
      return !selectedDevice?.value
    } else if (activeStep === 2) {
      return screenOrActivity === SCREEN_OR_ACTIVITY.ACTIVITY
        ? !navigation
        : !selectedScreen
    } else if (activeStep === 3) {
      if (isForWebView) {
        return !webviewTag
      } else {
        return !view.id && !view.tag
      }
    }
  })()

  const [logCount, setlogCount] = useState(0)
  const [qrCodeURL, setQrCodeURL] = useState("")
  const [loading, setLoading] = useState(false)

  useDeepCompareEffectNoCheck(() => {
    ;(async () => {
      setLoading(true)
      const response = await getTestDeviceAdditionQRURL(auth, appId, {
        platform,
      })

      setQrCodeURL(
        Array.isArray(response)
          ? `${response?.[0]}%26volatile_id%3D${
              selected === "" ? newVolatileID : selected
            }`
          : "",
      )
      setLoading(false)
    })()
  }, [appId, auth, platform, selected])

  useEffect(() => {
    if (logCount === 0) {
      logEvent("UIElement_ScreenFetched", {
        campaign_name: name,
        campaign_id: _id,
        test_device: selectedDevice.label,
      })
      setlogCount((logCount) => logCount + 1)
    }
    if (activeStep === 0) {
      mutate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logCount, logEvent])

  useEffect(() => {
    if (avfEnabled) {
      if (find_config?.eval && find_config?.nth_level_parent !== 0) {
        designPageDispatch({
          type: SET_IS_CONTENT_VIEW_SELECTION_MODE,
          value: true,
        })
      }
    }

    return () => {
      if (avfEnabled) {
        designPageDispatch({
          type: SET_IS_CONTENT_VIEW_SELECTION_MODE,
          value: false,
        })
        designPageDispatch({
          type: CLEAR_VIEW_MAP,
        })
        message.current = {
          message: "",
          type: "",
        }
      }
    }
  }, [
    avfEnabled,
    designPageDispatch,
    find_config?.eval,
    find_config?.nth_level_parent,
  ])

  const disableProceedBtn = useCallback(() => {
    if (isFromDynamicScript) {
      if (!view.tag && !view.id) {
        return true
      }
    }

    if (pending) {
      return true
    }

    if (isForWebView) {
      if (webviewTag === "" || view?.id === "") {
        return true
      }
    } else {
      if (avfEnabled) {
        if (isWeb && view?.id === "") {
          return true
        }
        if (
          !isWeb &&
          !findConfig?.view_id &&
          !findConfig?.view_tag &&
          !Number.isInteger(findConfig?.view_index) &&
          !findConfig?.view_path &&
          !findConfig?.eval &&
          !enableProceed
        ) {
          return true
        }
      } else {
        if (isWeb && view?.id === "") {
          return true
        }
        if (
          (view.search_type === PATH_OR_VIEW_ID.VIEW_ID &&
            view?.id === "" &&
            view?.tag === "") ||
          (view.search_type === PATH_OR_VIEW_ID.PATH && view?.path === "")
        ) {
          return true
        }
      }
    }

    if (isContentViewSelectionMode) {
      if (!findConfig?.eval?.val) {
        return true
      }
    }
  }, [
    avfEnabled,
    enableProceed,
    findConfig?.eval,
    findConfig?.view_id,
    findConfig?.view_index,
    findConfig?.view_path,
    findConfig?.view_tag,
    isContentViewSelectionMode,
    isForWebView,
    isWeb,
    pending,
    view?.id,
    view?.path,
    view.search_type,
    view?.tag,
    webviewTag,
    isFromDynamicScript,
  ])

  return (
    <>
      {!showDownloadContent && (
        <>
          <RowDiv styles={{ justifyContent: "space-between" }}>
            <HStack>
              {platform !== APP_PLATFORMS.web && (
                <IconButton
                  className={classes.backButton}
                  onClick={(e) => {
                    e.stopPropagation()
                    logEvent("UIElement_Dismissed", {
                      campaign_name: name,
                      campaign_id: _id,
                    })
                    handleClose()
                  }}
                >
                  <ChevronLeft style={{ color: "#002845" }} />
                </IconButton>
              )}
              <Typography
                variant="h5"
                color="textPrimary"
                style={{
                  fontWeight: 900,
                  marginLeft: platform === APP_PLATFORMS.web ? "40px" : "0px",
                }}
              >
                Select UI Element{" "}
                <ThemedTooltip
                  title="Your campaign will be shown on that UI element"
                  placement="right"
                  dark
                >
                  <HelpOutline style={{ fontSize: 14 }} />
                </ThemedTooltip>
              </Typography>
            </HStack>
            {platform !== APP_PLATFORMS.web && (
              <HStack justify={"end"}>
                <Button
                  onClick={() => {
                    setActiveStep(0)
                    designPageDispatch({
                      type: SET_SHOW_QR_IN_VIEW_ID_PATH_SELECTION,
                      value: !showQRinViewIdPathSelection,
                    })
                  }}
                  style={{
                    backgroundColor: "#f4f5f5",
                    paddingBlock: 18,
                    paddingInline: 22,
                    borderRadius: 30,
                    marginRight: 50,
                    marginBottom: 12,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#313957",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {showQRinViewIdPathSelection ? (
                      <>
                        <IconArrowLeft /> &nbsp; Back to select device from list
                      </>
                    ) : (
                      <>
                        <IconScan /> &nbsp; Try smart way to select device
                      </>
                    )}
                  </Typography>
                </Button>
              </HStack>
            )}
          </RowDiv>
          <Grid
            container
            spacing={1}
          >
            {!showTreeView && (
              <Grid
                item
                xs={isWeb ? 12 : 7}
                style={{ padding: isDynamicText ? 0 : "30px 50px" }}
              >
                {platform !== APP_PLATFORMS.web && backButton}
                <Stepper
                  activeStep={activeStep}
                  orientation="vertical"
                  style={{ paddingTop: 0, paddingInline: 0 }}
                  nonLinear
                >
                  {stepsByPlatform.map((label, index) => (
                    <Step
                      id={getContainerId(index)}
                      expanded={platform === APP_PLATFORMS.web}
                    >
                      <StepLabel
                        onClick={() => handleStep(index)}
                        classes={{
                          active: classes.active,
                          completed: classes.completed,
                          label: classes.label,
                          root: classes.root,
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                        optional={
                          <Typography variant="caption">
                            {getLabelDescription(index)}
                          </Typography>
                        }
                      >
                        <RowDiv
                          center
                          styles={{ gap: "5px" }}
                        >
                          <span
                            style={{
                              textTransform: "uppercase",
                              fontWeight: "800",
                              fontSize: "12px",
                              letterSpacing: "0.11em",
                            }}
                          >
                            {label}
                          </span>
                          {helper[index] !== "" && (
                            <ThemedTooltip
                              title={
                                <div style={{ whiteSpace: "pre-line" }}>
                                  {helper[index]}
                                </div>
                              }
                              dark
                            >
                              <HelpOutline style={{ fontSize: 16 }} />
                            </ThemedTooltip>
                          )}
                        </RowDiv>
                      </StepLabel>
                      <StepContent>
                        <VStack spacing="12px">
                          {/* Content */}
                          {isWeb
                            ? getContentForWeb(index)
                            : getStepContent(index)}

                          {/* Buttons */}
                          <HStack spacing="8px">
                            {/* 1. Continue Button */}
                            {index !== stepsByPlatform.length - 1 &&
                              platform !== APP_PLATFORMS.web && (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disabled={
                                    index === stepsByPlatform.length - 1 ||
                                    continueBtnDisabled
                                  }
                                  onClick={() => {
                                    logEvent("UIElement_ContinueClicked", {
                                      campaign_name: name,
                                      campaign_id: _id,
                                      type: (() => {
                                        if (index === 0) {
                                          return "Test-Device"
                                        } else if (index === 1) {
                                          return "Enable-Widget"
                                        } else if (index === 2) {
                                          return "Screen-Activity"
                                        }
                                      })(),
                                    })
                                    handleNext()
                                  }}
                                  style={{
                                    marginTop: "5px",
                                    marginRight: "5px",
                                  }}
                                >
                                  Continue
                                </Button>
                              )}

                            {/* 2. Proceed Button */}
                            {index === stepsByPlatform.length - 1 && (
                              <Button
                                size="large"
                                id="ui-proceed-button"
                                variant="contained"
                                color="primary"
                                disabled={disableProceedBtn()}
                                onClick={() => handleSaveAndProceed()}
                                style={{ marginTop: "5px", marginRight: "5px" }}
                              >
                                {!pending && "Proceed"}
                                {pending && <Loading size={18} />}
                              </Button>
                            )}

                            {/* 3. Back Button */}
                            {index !== 0 && platform !== APP_PLATFORMS.web && (
                              <Button
                                size="large"
                                disabled={index === 0}
                                onClick={() => {
                                  logEvent("UIElement_BackClicked", {
                                    campaign_name: name,
                                    campaign_id: _id,
                                    type: (() => {
                                      if (index === 0) {
                                        return "Test-Device"
                                      } else if (index === 1) {
                                        return "Enable-Widget"
                                      } else if (index === 2) {
                                        return "Screen-Activity"
                                      } else if (index === 3) {
                                        return "ViewId-Tag-Path"
                                      }
                                    })(),
                                  })
                                  handleBack()
                                }}
                                style={{ marginTop: "5px" }}
                              >
                                Back
                              </Button>
                            )}
                          </HStack>

                          {isFromDynamicScript &&
                            index === stepsByPlatform.length - 1 &&
                            disableProceedBtn() &&
                            !view.tag &&
                            !view.id && (
                              <Alert
                                severity="warning"
                                style={{
                                  borderRadius: 12,
                                  width: "fit-content",
                                  fontWeight: 500,
                                  fontSize: 13,
                                }}
                                icon={<ErrorOutlineIcon fontSize="inherit" />}
                              >
                                Can't select the element because the ID and Tag
                                are empty.
                              </Alert>
                            )}
                        </VStack>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            )}

            {/* Tree View */}
            {showTreeView && (
              <Grid
                item
                xs={7}
                style={{
                  padding: isDynamicText ? 0 : "30px 50px",
                }}
              >
                {backButton}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid #E4E7E9",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "4px 8px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontWeight: 700 }}
                    >
                      Tree View
                    </Typography>
                    <Button
                      variant="text"
                      style={{
                        textDecoration: "none",
                        textTransform: "none",
                        fontSize: 13,
                        fontWeight: 500,
                      }}
                      color="primary"
                      onClick={() => {
                        logEvent("UIElement_TreeViewClosedClicked", {
                          campaign_name: name,
                          campaign_id: _id,
                        })
                        setShowTreeView((previousValue) => !previousValue)
                      }}
                    >
                      X Close
                    </Button>
                  </div>
                  <ModuleDivider styles={{ marginTop: 0 }} />
                  <div style={{ marginLeft: 10 }}>
                    <TreeView
                      handleViewSelect={handleViewSelect}
                      ratio={ratio}
                    />
                  </div>
                </div>
              </Grid>
            )}

            {!isWeb && (
              <Grid
                item
                xs={5}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBottom: "50px",
                  paddingTop: 16,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SSEDevicePreview
                    appId={appId}
                    platform={platform}
                    saveImages={saveImages}
                    setRatio={(ratio) => setRatio(ratio)}
                    imageConfig={{
                      width:
                        orientation === ""
                          ? 270
                          : orientation === ORIENTATION_ENUM.PORTRAIT
                            ? 270
                            : 540,
                      height:
                        orientation === ""
                          ? 540
                          : orientation === ORIENTATION_ENUM.PORTRAIT
                            ? 540
                            : 270,
                    }}
                    handleSSEDataReceive={(data) => {
                      const {
                        screen: { navigation = "", orientation, screen = "" },
                      } = data
                      designPageDispatch({
                        type: SET_SSE_DATA,
                        value: data,
                      })
                      designPageDispatch({
                        type: SET_ORIENTATION,
                        value: orientation,
                      })
                      designPageDispatch({
                        type: SET_ACTIVITY,
                        value: navigation,
                      })
                      designPageDispatch({
                        type: SET_SCREEN,
                        value: screen,
                      })
                      logEvent("ScreenSelected", {
                        screen_name:
                          screenOrActivity === SCREEN_OR_ACTIVITY.ACTIVITY
                            ? navigation
                            : screen,
                        experience_type: experience_type,
                      })
                      setScreenOrActivity(screen !== "" ? "screen" : "activity")
                      setNavigation(navigation)
                      setSelectedScreen(screen)
                    }}
                    handleViewSelect={handleViewSelect}
                    selectedDevice={
                      showQRinViewIdPathSelection
                        ? selectedDevice?.value === ""
                          ? newVolatileID
                          : selectedDevice?.value
                        : selectedDevice?.value
                    }
                    setSelectedDeviceId={(selectedId) => {
                      handleTestDeviceSelection(selectedId)
                    }}
                  />
                  <Button
                    variant="text"
                    style={{
                      textDecoration: "underline",
                      textTransform: "none",
                    }}
                    disabled={!currentImage}
                    onClick={() => {
                      logEvent("UIElement_UIHierarchyViewClicked", {
                        campaign_name: name,
                        campaign_id: _id,
                      })
                      setShowTreeView((previousValue) => !previousValue)
                    }}
                  >
                    Switch To UI Hierarchy View
                  </Button>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #CCD4DA",
                      borderRadius: 100,
                      alignItems: "flex-end",
                      padding: "6px 10px",
                      textTransform: "capitalize",
                    }}
                  >
                    {currentImage ? (
                      <>
                        <PhoneIphoneIcon color="action" /> {orientation}
                      </>
                    ) : (
                      "Portrait"
                    )}
                  </div>
                </div>
              </Grid>
            )}
            {isWeb && (
              <SSEDesignPreviewForWeb
                appId={appId}
                selected={selected === "" ? newVolatileID : selected}
                setSelectedDevice={(label, value) => {
                  fetchTestDevices()
                  setSelectedDevice({ label, value })
                  campaignCreatorDispatch({
                    type: SET_SELECTED_TEST_DEVICE,
                    value: { label, value },
                  })
                }}
                handleSSEDataReceive={(navigation, idAndPath, config = {}) => {
                  logEvent("ScreenSelected", {
                    screen_name: navigation,
                    experience_type: experience_type,
                  })
                  setNavigation(navigation)
                  setURL(navigation)
                  if (avfEnabled) {
                    handleViewSelect({
                      id: config.id,
                      is_content_based: config.is_content_based,
                      content: config.content,
                    })
                  } else {
                    const [id] = idAndPath.split("___")
                    handleViewSelect({ id })
                  }
                }}
              />
            )}
          </Grid>
        </>
      )}
      {showDownloadContent && (
        <ColumnDiv
          styles={{
            padding: "50px",
            paddingTop: "0px",
            margin: "0px",
          }}
        >
          <Button
            className={classes.downloadBackButton}
            onClick={() => {
              fetchTestDevices()
              if (showTestDevicesSection) {
                setShowTestDevicesSection(false)
              } else {
                setShowDownloadContent(false)
              }
            }}
            variant="text"
            startIcon={<ChevronLeft style={{ color: "#002845" }} />}
            style={{
              marginLeft: "-10px",
              marginBottom: "10px",
            }}
          >
            Back
          </Button>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              xs={8}
            >
              <ColumnDiv styles={{ gap: "20px" }}>
                <DownloadApxorAppContent
                  platform={platform}
                  handleOnBackButtonClick={() => {
                    fetchTestDevices()
                    setShowDownloadContent(false)
                  }}
                  showQRForTestDeviceAddition={showQRForTestDeviceAddition}
                  setShowQRForTestDeviceAddition={(value) =>
                    designPageDispatch({
                      type: SET_SHOW_QR_FOR_TEST_DEVICE_ADDITION,
                      value,
                    })
                  }
                />
                {!showQRForTestDeviceAddition && (
                  <>
                    <Divider variant="fullWidth" />
                    <ColumnDiv styles={{ border: "1px solid #CCD4DA" }}>
                      <RowDiv
                        center
                        styles={{
                          justifyContent: "space-between",
                          borderBottom: "1px solid #CCD4DA",
                          padding: 10,
                        }}
                      >
                        <RowDiv
                          center
                          styles={{ gap: "4px" }}
                        >
                          <Typography
                            variant="body1"
                            style={{ fontWeight: 800 }}
                          >
                            ALL DEVICES{" "}
                          </Typography>
                          {fetching && (
                            <Loading
                              color="primary"
                              size={22}
                            />
                          )}
                        </RowDiv>
                        <Tooltip
                          title="Refetch Test Devices"
                          placement="top"
                          arrow
                        >
                          <IconButton
                            size="small"
                            onClick={fetchTestDevices}
                          >
                            <RefreshIcon
                              fontSize="small"
                              color="primary"
                            />
                          </IconButton>
                        </Tooltip>
                      </RowDiv>
                      <List
                        style={{
                          maxHeight: 380,
                          overflow: "scroll",
                        }}
                      >
                        {test_devices.map((device, index) => (
                          <ListItem key={index}>
                            <ListItemText>
                              {`${
                                device.hasOwnProperty("nick_name") &&
                                device.nick_name !== ""
                                  ? device.nick_name + " - "
                                  : device.id + " - "
                              } ${device.model}`}
                            </ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    </ColumnDiv>
                  </>
                )}
              </ColumnDiv>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {IconImageComponent(mobile, "Mobile", {
                width: 270,
                height: "unset",
              })}
            </Grid>
          </Grid>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => {
              fetchTestDevices()
              setShowDownloadContent(false)
            }}
            style={{
              textTransform: "none",
              alignSelf: "flex-end",
            }}
          >
            Done
          </Button>
        </ColumnDiv>
      )}
    </>
  )
}
